export enum applicationStatus {
  'accepted' = 'accepted',
  'rejected' = 'rejected',
  'toCheck' = 'toCheck'
}

export const applicationStatusTranslation: { [k: string]: string } = {
  [applicationStatus.accepted]: 'Подтверждена',
  [applicationStatus.rejected]: 'Отклонена',
  [applicationStatus.toCheck]: 'На проверке'
}
