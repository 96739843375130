















import { Component, Vue } from 'vue-property-decorator'
import ProjectFactory from '@/components/projects/project-add/helpers/factory'
import ProjectUpsertComponent from '@/components/projects/common/ProjectUpsertComponent.vue'
import ProjectsAPI from '@/components/projects/common/helpers/requests'
import { projectRoles } from '@/config/projects/projectRoles'
import { applicationStatus } from '@/config/projects/applicationStatus'
import { userMapper } from '@/store/modules/user'

const Mappers = Vue.extend({
  computed: {
    ...userMapper.mapGetters(['currentUser'])
  }
})

@Component({
  components: { ProjectUpsertComponent }
})
export default class ProjectAdd extends Mappers {
  private model = ProjectFactory.createEmpty()

  private async createProject(params: any) {
    const project = (await ProjectsAPI.createProject(params)).data.project
    await ProjectsAPI.submitApplication(project.id, this.currentUser.id, {
      projectRoles: [
        projectRoles.participant,
        projectRoles.author,
        projectRoles.leader
      ],
      applicationStatus: applicationStatus.accepted
    })
    this.$router.push('/projects')
  }
}
