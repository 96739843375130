export enum projectRoles {
  'participant' = 'participant',
  'author' = 'author',
  'leader' = 'leader'
}

export const projectRolesTranslation: { [k: string]: string } = {
  [projectRoles.participant]: 'Участник',
  [projectRoles.author]: 'Автор',
  [projectRoles.leader]: 'Руководитель'
}

export const projectRolesBadges: { [k: string]: string } = {
  [projectRoles.participant]: 'Уч',
  [projectRoles.author]: 'Авт',
  [projectRoles.leader]: 'Рук'
}
