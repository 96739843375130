export const editorConfig = {
  toolbar: {
    items: [
      'heading',
      'bold',
      'italic',
      'link',
      'alignment',
      'bulletedList',
      'numberedList',
      '|',
      'outdent',
      'indent',
      'blockQuote',
      'undo',
      'redo',
      'Replace'
    ]
  }
}
