import { http } from '@/api/requests/httpAxios'
import { AxiosPromise } from 'axios'
import createURLParams from '@/api/datasource/createURLParams'
import { projectStatus } from '@/config/projects/status'

export default class ProjectsAPI {
  public static createProject(body: any): AxiosPromise {
    return http.post('/project/new', body)
  }

  public static getProjects(
    limit: number,
    offset: number,
    sort: { [k: string]: any } | null,
    filter: { [k: string]: any } | null
  ): AxiosPromise {
    const params = createURLParams(limit, offset, sort, filter, false)
    return http.get('/projects', { params })
  }

  public static getProject(id: number): AxiosPromise {
    return http.get(`/project/${id}`)
  }

  public static editProject(id: number, body: any): AxiosPromise {
    return http.put(`/project/edit/${id}`, body)
  }

  public static async addFileToProject(id: number, body: any) {
    const formData = new FormData()
    for (const key of Object.keys(body)) {
      formData.append(key, body[key])
    }

    return http.post(`/project/${id}/file/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
  }

  public static deleteFileFromProject(id: number): AxiosPromise {
    return http.delete(`/project/file/delete/${id}`)
  }

  public static submitApplication(
    projectId: number,
    userId: number,
    body: any = {}
  ): AxiosPromise {
    return http.post(`/application/project/${projectId}/user/${userId}`, body)
  }

  public static deleteApplication(id: number): AxiosPromise {
    return http.delete(`/application/${id}`)
  }

  public static getProjectApplications(filter: any = {}): AxiosPromise {
    const params = new URLSearchParams()
    params.append('filter', JSON.stringify(filter))
    return http.get(`/applications?${params.toString()}`)
  }

  public static editApplication(id: number, body: any = {}): AxiosPromise {
    return http.put(`/application/${id}`, body)
  }

  public static changeStatus(id: number, status: projectStatus) {
    return http.put(`/project/edit/${id}`, { status })
  }

  public static deleteProject(id: number): AxiosPromise {
    return http.delete(`/project/${id}`)
  }
}
