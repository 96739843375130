import _ from 'lodash'
import validators from '@/utils/validators'
import {
  FormSchema,
  FormOptions,
  GenField,
  localedValidtorFn,
  ValidationResults
} from 'vue-form-generator'

export function getFields(schema: FormSchema): GenField[] {
  const fields: GenField[] = []
  if (schema.fields) {
    for (const field of schema.fields) {
      fields.push(field)
    }
  }
  if (schema.groups) {
    for (const group of schema.groups) {
      for (const field of group.fields) {
        fields.push(field)
      }
    }
  }
  return fields
}

export function convertValidator(validator: localedValidtorFn | string) {
  if (_.isFunction(validator)) {
    return validator
  }
  const func = validators[validator as string]
  if (_.isNil(func)) {
    console.warn(`${validator} is not a validator function`)
  }
  return func
}

export function getValidators(field: GenField): localedValidtorFn[] {
  if (_.isNil(field.validator)) {
    return []
  }
  if (_.isArray(field.validator)) {
    return field.validator.map(validator => convertValidator(validator))
  }
  return [convertValidator(field.validator)]
}

export function validateSchemaSync(
  schema: FormSchema,
  model: any,
  index?: number,
  formOptions?: FormOptions
): ValidationResults {
  const fields = getFields(schema)
  const allErrors = []
  for (const field of fields) {
    if (
      (field.readonly === true && !formOptions?.validateReadonly) ||
      (field.disabled === true && !formOptions?.validateDisabled)
    ) {
      continue
    }
    const validators = getValidators(field)
    const value = _.get(model, field.model)
    for (const validator of validators) {
      const errors = validator(value, field, model)
      if (errors) {
        for (const error of errors) {
          allErrors.push({
            field,
            error,
            index
          })
        }
      }
    }
  }
  return allErrors
}

export function validateValueSync(
  field: string,
  model: any,
  validateFunc: (value: any) => string[],
  index?: number
): ValidationResults {
  const value = _.get(model, field)
  const errors = validateFunc(value)
  const allErrors = []

  if (errors) {
    for (const error of errors) {
      allErrors.push({
        field: { type: 'validate', model },
        error,
        index
      })
    }
  }

  return allErrors
}

export default function validatePerson(name: string) {
  const split = name.split(',').length
  return split !== 3 && split !== 2 && split !== 4
    ? [
        'Неправильный формат записи! Требуется: "должность, уч.степень (если есть), уч. звание (если есть) И.О. Фамилия"'
      ]
    : []
}

export function validateResultsToText(errors: any[]) {
  if (_.isEmpty(errors)) {
    return []
  }
  return errors.map(e => `${e.field.label ?? e.field.title} - ${e.error}`)
}
