









import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import validators from '@/utils/validators'
import ProjectFactory from '@/components/projects/project-add/helpers/factory'
import { toastMapper } from '@/store/modules/toast'
import { validateSchemaSync } from '@/utils/validation'
import { editorConfig } from '@/config/ckeditor'

const Mapper = Vue.extend({
  methods: {
    ...toastMapper.mapActions(['pushToast'])
  }
})

@Component
export default class ProjectUpsertComponent extends Mapper {
  @Prop() project!: any

  @Watch('project', { deep: true })
  private onProjectChanged() {
    this.model = this.project || ProjectFactory.createEmpty()
  }
  private model = this.project || ProjectFactory.createEmpty()

  private schema = {
    fields: [
      {
        type: 'bootstrap',
        inputType: 'text',
        label: 'Название',
        model: 'title',
        required: true,
        styleClasses: 'wm-100',
        validator: validators.required
      },
      {
        type: 'bootstrapTextArea',
        label: 'Краткое описание',
        model: 'shortDescription',
        rows: 5,
        'max-rows': 20,
        required: true,
        styleClasses: 'w-100',
        validator: validators.required,
        hint: 'Краткое описание не должно содержать больше 255 символов'
      },
      {
        type: 'CKEditor',
        label: 'Подробное описание',
        model: 'description',
        required: true,
        rows: 15,
        'max-rows': 20,
        styleClasses: 'w-100',
        validator: validators.required,
        hint: '',
        editorConfig: editorConfig
      }
    ]
  }

  private validateModel() {
    const errors = validateSchemaSync(this.schema, this.model)
    const fields = errors.map((error: any) => error.field.label)
    return fields.map(
      (field: any) => `Не заполнено поле <strong>"${field}"</strong>`
    )
  }

  private onSave() {
    const errors = this.validateModel()
    if (errors.length) {
      this.pushToast({
        error: true,
        time: 10,
        title: 'Добавление проекта',
        message: errors.join('<br/>')
      })
    } else {
      this.$emit('save', this.model)
    }
  }
}
