import { projectStatus } from '@/config/projects/status'

export default class ProjectFactory {
  public static createEmpty() {
    return {
      title: '',
      description: '',
      shortDescription: '',
      avatar: null,
      status: projectStatus.draft
    }
  }
}
