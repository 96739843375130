import { validators } from 'vue-form-generator'

const localeMessages = {
  fieldIsRequired: 'Это поле обязательно!',
  invalidFormat: 'Неверный формат!',

  numberTooSmall: 'Число слишком маленькое! Минимум: {0}',
  numberTooBig: 'Число слишком большое! Максимум: {0}',
  invalidNumber: 'Неправильное число',
  invalidInteger: 'Значение должно быть целым числом',

  textTooSmall: 'Текст слишком короткий! Сейчас: {0}, Минимум: {1}',
  textTooBig: 'Текст слишком длинный! Сейчас: {0}, Максимум: {1}',
  thisNotText: 'Это не текст!',

  thisNotArray: 'Это не массив!',

  selectMinItems: 'Выберите минимум пунктов: {0}!',
  selectMaxItems: 'Выберите не более: {0}!',

  invalidDate: 'Неверная дата!',
  dateIsEarly: 'Дата слишком ранняя! Сейчас: {0}, Минимум: {1}',
  dateIsLate: 'Дата слишком поздняя! Сейчас: {0}, Максимум: {1}',

  invalidEmail: 'Неверный e-mail!',
  invalidURL: 'Неверный URL!',

  invalidCard: 'Неверная карточка!',
  invalidCardNumber: 'Неверный номер карточки!',

  invalidTextContainNumber: 'Неверный текст! Текст не может содержать числа',
  invalidTextContainSpec: 'Неверный текст! Текст не может содержать спецсимволы'
}

const localValidators: any = {}

for (const [key, f] of Object.entries(validators)) {
  if (key !== 'resources') {
    localValidators[key] = f.locale(localeMessages)
  }
}

export default localValidators
