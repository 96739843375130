




import { Component, Vue } from 'vue-property-decorator'
import ProjectAdd from '@/components/projects/project-add/index.vue'

@Component({ components: { ProjectAdd } })
export default class ProjectAddRoute extends Vue {}
