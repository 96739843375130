export enum projectStatus {
  'draft' = 'draft', // Черновик
  'application' = 'application', // Заявка
  'confirmed' = 'confirmed' // Проект
}

export const projectStatusTranslation: { [k: string]: string } = {
  [projectStatus.application]: 'Заявка',
  [projectStatus.draft]: 'Черновик',
  [projectStatus.confirmed]: 'Проект'
}

export const projectStatusDescription: { [k: string]: string } = {
  [projectStatus.application]:
    'Проект со статусом "Заявка" виден ученикам в списке проектов и у них появляется возможность подать заявку на проект. Для того чтобы перевести проект в данное состояние, необходимо заполнить все обязательные поля.',
  [projectStatus.draft]:
    'Данный статус делает проект неактивным и ученики не видят его в списке проектов.',
  [projectStatus.confirmed]:
    ' Данный статус делает проект активным. Для того чтобы перевести проект в данное состояние, необходимо набрать достаточно участников.'
}
